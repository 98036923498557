import { useBillToCompanyMappingVM } from "presentation/hook/BillToCompanyMapping/useBillToCompanyMappingVM";
import { useBillToCompanyMappingTracked } from "presentation/store/BillToCompanyMapping/BillToCompanyMappingProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import BillToCompanyMappingEditPanel from "./RightPanel/BillToCompanyMappingEditPanel";
import BillToCompanyMappingTablePanel from "./Table/BillToCompanyMappingTablePanel";


const BillToCompanyMappingMaintenance: React.FC = () => {
    const [billToCompanyMappingState] = useBillToCompanyMappingTracked();
    const [isLoading, setIsLoading] = useState(true);
    const billToCompanyMappingVM = useBillToCompanyMappingVM();
    const { isShowEditPanel } = billToCompanyMappingState;


    useEffect(() => {
        const initialScreen = async () => {
            try {
                const results = await Promise.allSettled([
                    billToCompanyMappingVM.loadDropdownOption(),
                ]);
                results?.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then((data) => {
            billToCompanyMappingVM.searchAllBillToCompanyMappingList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        }).catch(error => {
            setIsLoading(false)
        });

    }, [billToCompanyMappingVM])

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;

    return <>
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}>

            {!(isShowEditPanel) &&
                <>
                    <BillToCompanyMappingTablePanel /></>}
            {(isShowEditPanel) &&
                <SliderPanel
                    isOpen={true}
                    draggable={false}
                    leftSectionWidth={"100%"}
                    rightSectionWidth={"0%"}
                    leftChildren={<BillToCompanyMappingEditPanel />}
                />}
        </div>
    </>
}

export default memo(BillToCompanyMappingMaintenance);